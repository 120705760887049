import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import objects from "../../images/MainSystemFunctional/objects.png";
import services from "../../images/MainSystemFunctional/services.png";
import video from "../../images/MainSystemFunctional/video.png";
import communication from "../../images/MainSystemFunctional/communication.png";
import ThemeContext from "../../contexts/ThemeContext";
import "./styles.scss";

const MainSystemFunctional = () => {
  const { t } = useTranslation();
  const context = useContext(ThemeContext);
  const scrollToForm = context.scrollToForm;

  return (
    <>
      <Row lg={12} xl={12}>
        <Col
          lg={12}
          xl={12}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h2 className="main-functional-title">
            {t("MainSystemFunctional.23")}
          </h2>
        </Col>
      </Row>
      <Row className="main-functional-cards-wrapper">
        <Col className="main-functional-cards-tablet" lg={3} xl={3}>
          <div className="main-functional-card orange">
            <div className="card-top-for-mobile">
              <div className="card-image">
                <img src={objects} alt="objects" />
              </div>
              <span className="card-title">{t("MainSystemFunctional.1")}</span>
            </div>
            <div className="card-image">
              <img src={objects} alt="objects" />
            </div>
            <span className="card-title">{t("MainSystemFunctional.1")}</span>
            <ul className="card-list-content">
              <li className="card-list-item">{t("MainSystemFunctional.2")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.3")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.4")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.5")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.6")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.7")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.8")}</li>
            </ul>
          </div>
        </Col>
        <Col className="main-functional-cards-tablet" lg={3} xl={3}>
          <div className="main-functional-card green">
            <div className="card-top-for-mobile">
              <div className="card-image">
                <img src={services} alt="services" />
              </div>
              <span
                className="card-title"
              >
                {t("MainSystemFunctional.9")}
              </span>
            </div>
            <div className="card-image">
              <img src={services} alt="services" />
            </div>
            <span
              className="card-title"
            >
              {t("MainSystemFunctional.9")}
            </span>
            <ul className="card-list-content">
              <li className="card-list-item">{t("MainSystemFunctional.10")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.11")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.12")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.13")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.25")}</li>
            </ul>
          </div>
        </Col>
        <Col className="main-functional-cards-tablet" lg={3} xl={3}>
          <div className="main-functional-card blue">
            <div className="card-top-for-mobile">
              <div className="card-image">
                <img src={video} alt="video" />
              </div>
              <span className="card-title">{t("MainSystemFunctional.14")}</span>
            </div>
            <div className="card-image">
              <img src={video} alt="video" />
            </div>
            <span className="card-title">{t("MainSystemFunctional.14")}</span>
            <ul className="card-list-content">
              <li className="card-list-item">{t("MainSystemFunctional.15")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.16")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.17")}</li>
            </ul>
          </div>
        </Col>
        <Col className="main-functional-cards-tablet" lg={3} xl={3}>
          <div className="main-functional-card purple">
            <div className="card-top-for-mobile">
              <div className="card-image">
                <img src={communication} alt="communication" />
              </div>
              <span className="card-title">{t("MainSystemFunctional.18")}</span>
            </div>
            <div className="card-image">
              <img src={communication} alt="communication" />
            </div>
            <span className="card-title">{t("MainSystemFunctional.18")}</span>
            <ul className="card-list-content">
              <li className="card-list-item">{t("MainSystemFunctional.19")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.20")}</li>
              <li className="card-list-item">{t("MainSystemFunctional.21")}</li>

            </ul>
          </div>
        </Col>
      </Row>
      <Row className="main-functional-button-wrapper" lg={12} xl={12}>
        <Col lg={12} xl={12}>
          <span
            className="main-functional-button"
            onClick={() => scrollToForm(context)}
          >
            {t("MainSystemFunctional.24")}
          </span>
        </Col>
      </Row>
    </>
  );
};

export default MainSystemFunctional;
