import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import purpleLogo from "../../images/NewOpportunities/purple-logo.png";
import blueLogo from "../../images/NewOpportunities/blue-logo.png";
import greenLogo from "../../images/NewOpportunities/green-logo.png";
import orangeLogo from "../../images/NewOpportunities/orange-logo.png";
import "./styles.scss";

const NewOpportunities = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row lg={12} xl={12}>
        <Col
          lg={12}
          xl={12}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <div className="new-opportunities-title-wrapper">
            <span className="new-opportunities-subtitle">Utilitium</span>
            <h2 className="new-opportunities-title">
              {t("NewOpportunities.1")}
            </h2>
          </div>
        </Col>
      </Row>
      <Row
        className="new-opportunities-card-wrapper top-card-wrapper"
        lg={12}
        xl={12}
      >
        <Col lg={6} xl={6}>
          <div className="new-opportunities-card">
            <div className="card-logo">
              <img src={purpleLogo} alt="purpleLogo" />
            </div>
            <div className="card-content">
              <span className="card-content-title">
                {t("NewOpportunities.2")}
              </span>
              <p className="card-content-text">{t("NewOpportunities.3")}<span>Utilitium</span>{t("NewOpportunities.31")}</p>
            </div>
          </div>
        </Col>
        <Col lg={6} xl={6}>
          <div className="new-opportunities-card">
            <div className="card-logo">
              <img src={blueLogo} alt="blueLogo" />
            </div>
            <div className="card-content card-blue">
              <span className="card-content-title card-content-title-blue">
                <span>{t("NewOpportunities.4")}&nbsp;</span>
                <span>{t("NewOpportunities.41")}</span>
              </span>
              <p className="card-content-text"><span>Utilitium</span>{t("NewOpportunities.5")}</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="new-opportunities-card-wrapper bottom-card-wrapper">
        <Col lg={6} xl={6}>
          <div className="new-opportunities-card">
            <div className="card-logo">
              <img src={orangeLogo} alt="orangeLogo" />
            </div>
            <div className="card-content card-orange">
              <span className="card-content-title card-content-title-orange modern-technologies">
                {t("NewOpportunities.6")}
              </span>
              <p className="card-content-text">{t("NewOpportunities.7")}<span>Utilitium</span>{t("NewOpportunities.71")}</p>
            </div>
          </div>
        </Col>
        <Col lg={6} xl={6}>
          <div className="new-opportunities-card">
            <div className="card-logo">
              <img src={greenLogo} alt="greenLogo" />
            </div>
            <div className="card-content card-green">
              <span className="card-content-title">
                {t("NewOpportunities.8")}
              </span>
              <p className="card-content-text"><span>Utilitium</span>{t("NewOpportunities.9")}</p>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewOpportunities;
