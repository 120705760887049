import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../contexts/ThemeContext";
import ComplexBLue from "../../images/ComplexSystem/complex1.svg";
import ComplexOrange from "../../images/ComplexSystem/complex2.svg";
import ComplexGreen from "../../images/ComplexSystem/complex3.svg";
import "./styles.scss";

const ComplexSystem = () => {
  const { t } = useTranslation();
  const context = useContext(ThemeContext);
  const scrollToForm = context.scrollToForm;

  return (
    <Row className="complex-system-wrapper" lg={12} xl={12} md={12}>
      <Col lg={12} xl={12} md={12}>
        <h1 className="complex-system-title">{t("Complex.1")}</h1>
        <ul className="complex-system-list">
          <li className="list-item item blue">
            <img src={ComplexBLue} alt="Utilitium" />
            <span>{t("Complex.2")}</span>
          </li>
          <li className="list-item-rectangle item" />
          <li className="list-item item orange">
            <img src={ComplexOrange} alt="Utilitium" />
            <span>{t("Complex.3")}</span>
          </li>
          <li className="list-item-rectangle item" />
          <li className="list-item item green">
            <img src={ComplexGreen} alt="Utilitium" />
            <span>{t("Complex.4")}</span>
          </li>
        </ul>
        <div className="complex-system-links">
          <span
            className="complex-system-consultation link"
            onClick={() => scrollToForm(context)}
          >
            {t("Complex.5")}
          </span>
          <span
            href="#"
            className="complex-system-demonstration link"
            onClick={() => scrollToForm(context)}
          >
            {t("Complex.6")}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default ComplexSystem;
