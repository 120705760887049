import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const ModernTechnologies = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col lg={12} xl={12} md={12} xs={12}>
          <div className="moder-technologies-title-wrapper">
            <span className="moder-technologies-subtitle">
              {t("ModernTechnologies.15")}{" "}
              <span className="brand-name">Utilitum</span>{" "}
              {t("ModernTechnologies.1")}
            </span>
            <h2 className="moder-technologies-title">
              {t("ModernTechnologies.2")}
            </h2>
          </div>
        </Col>
      </Row>
      <Row className="moder-technologies-mobile-container">
        <Col lg={4} xl={4} md={4} xs={4}>
          <div className="moder-technologies-card-wrapper">
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.3")}</span>
              <p className="card-content">
                <span>Utilitium</span> {t("ModernTechnologies.4")}
              </p>
            </div>
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.9")}</span>
              <p className="card-content">
                <span>Utilitium</span> {t("ModernTechnologies.10")}
              </p>
            </div>
          </div>
        </Col>
        <Col lg={4} xl={4} md={4} xs={4}>
          <div className="moder-technologies-card-wrapper">
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.5")}</span>
              <p className="card-content">{t("ModernTechnologies.6")}</p>
            </div>
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.11")}</span>
              <p className="card-content">
                {t("ModernTechnologies.12")}
                <span> Utilitium </span>
                {t("ModernTechnologies.121")}
              </p>
            </div>
          </div>
        </Col>
        <Col lg={4} xl={4} md={4} xs={4}>
          <div className="moder-technologies-card-wrapper last">
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.7")}</span>
              <p className="card-content">
                {t("ModernTechnologies.8")} <span>Utilitium</span>,{" "}
                {t("ModernTechnologies.81")}
              </p>
            </div>
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.13")}</span>
              <p className="card-content">
                <span>Utilitium</span> {t("ModernTechnologies.14")}
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="moder-technologies-mobile-container restructuring">
        <Col lg={4} xl={4} md={4} xs={4}>
          <div className="moder-technologies-card-wrapper">
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.3")}</span>
              <p className="card-content">
                <span>Utilitium</span> {t("ModernTechnologies.4")}
              </p>
            </div>
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.5")}</span>
              <p className="card-content">{t("ModernTechnologies.6")}</p>
            </div>
          </div>
        </Col>
        <Col lg={4} xl={4} md={4} xs={4}>
          <div className="moder-technologies-card-wrapper">
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.7")}</span>
              <p className="card-content">
                {t("ModernTechnologies.8")} <span>Utilitium</span>,{" "}
                {t("ModernTechnologies.81")}
              </p>
            </div>
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.9")}</span>
              <p className="card-content">
                <span>Utilitium</span> {t("ModernTechnologies.10")}
              </p>
            </div>
          </div>
        </Col>
        <Col lg={4} xl={4} md={4} xs={4}>
          <div className="moder-technologies-card-wrapper last">
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.11")}</span>
              <p className="card-content">
                {t("ModernTechnologies.12")}
                <span> Utilitium </span>
                {t("ModernTechnologies.121")}
              </p>
            </div>
            <div className="moder-technologies-card">
              <span className="card-title">{t("ModernTechnologies.13")}</span>
              <p className="card-content">
                <span>Utilitium</span> {t("ModernTechnologies.14")}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ModernTechnologies;
