import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import questionImage from "../../images/QuestionsSection/question.png";
import "./styles.scss";

const QuestionsSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="questions-section-wrapper" xs={12}>
        <Col className="questions-section-tablet" lg={5} xl={6} xs={6}>
          <div className="questions-section-image">
            <img className="image" src={questionImage} alt="questionImage" />
          </div>
        </Col>
        <Col className="questions-section-tablet" lg={7} xl={6} xs={6}>
          <div className="questions-section-cards">
            <div className="cards-container top">
              <div className="questions-section-card">
                <span className="card-title card-title-whois">
                  <span>{t("QuestionSection.1")}&nbsp;</span>
                  <span>{t("QuestionSection.11")}</span>
                </span>
                <p className="card-text"><span>Utilitium</span>{t("QuestionSection.2")}</p>
              </div>
              <div className="questions-section-card">
                <span className="card-title">{t("QuestionSection.3")}</span>
                <p className="card-text"><span>Utilitium</span>{t("QuestionSection.4")}</p>
              </div>
            </div>
            <div className="cards-container">
              <div className="questions-section-card">
                <span className="card-title personal-styles">
                  {t("QuestionSection.5")}
                </span>
                <p className="card-text">{t("QuestionSection.6")}</p>
              </div>
              <div className="questions-section-card last-card">
                <span className="card-title">{t("QuestionSection.7")}</span>
                <p className="card-text">{t("QuestionSection.8")}</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default QuestionsSection;
