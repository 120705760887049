import React, { useContext, useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import desktopLogo from "../../images/ManagementCompany/desktop.png";
import ENdeskSlide1 from "../../images/ManagementCompany/ENdeskSlide1.png";
import ENdeskSlide2 from "../../images/ManagementCompany/ENdeskSlide2.png";
import ENdeskSlide3 from "../../images/ManagementCompany/ENdeskSlide3.png";
import RUdeskSlide1 from "../../images/ManagementCompany/RUdeskSlide1.png";
import RUdeskSlide2 from "../../images/ManagementCompany/RUdeskSlide2.png";
import RUdeskSlide3 from "../../images/ManagementCompany/RUdeskSlide3.png";
import UAdeskSlide1 from "../../images/ManagementCompany/UAdeskSlide1.png";
import UAdeskSlide2 from "../../images/ManagementCompany/UAdeskSlide2.png";
import UAdeskSlide3 from "../../images/ManagementCompany/UAdeskSlide3.png";
import Slider from "react-slick";
import ThemeContext from "../../contexts/ThemeContext";
import "./styles.scss";

const ManagementCompany = () => {
  const [renderSlider, setRenderSlider] = useState(false);

  useEffect(
    () => {
      setRenderSlider(true);
    }, []);

  const { t, i18n } = useTranslation();

  const context = useContext(ThemeContext);

  const scrollToForm = () => {
    if (context.formRef && context.formRef.current) {
      const form = context.formRef.current;
      form.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const deskSlide1 = () => {
    let actualLeng = i18n.language

    switch (actualLeng) {
      case "en":
        return ENdeskSlide1;
      case "ru":
        return RUdeskSlide1;
      case "ua":
        return UAdeskSlide1;
        default:
          return null;
    }
  }

  const deskSlide2 = () => {
    let actualLeng = i18n.language

    switch (actualLeng) {
      case "en":
        return ENdeskSlide2;
      case "ru":
        return RUdeskSlide2;
      case "ua":
        return UAdeskSlide2;
        default:
          return null;
    }
  }

  const deskSlide3 = () => {
    let actualLeng = i18n.language

    switch (actualLeng) {
      case "en":
        return ENdeskSlide3;
      case "ru":
        return RUdeskSlide3;
      case "ua":
        return UAdeskSlide3;
        default:
          return null;
    }
  }

  return (
    <>
      <Row lg={12} xl={12}>
        <Col
          lg={12}
          xl={12}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <div className="management-company-title-wrapper">
            <span className="management-company-subtitle">Utilitium</span>
            <h2 className="management-company-title">
              {t("ManagementCompany.1")}
            </h2>
          </div>
        </Col>
      </Row>
      <Row className="management-company-content-wrapper" lg={12} xl={12}>
        <Col lg={6} xl={6} md={4}>
          <div className="management-company-content">
            <div className="lists-wrapper">
              <ul className="management-company-list left-side">
                <li className="list-item">{t("ManagementCompany.2")}</li>
                <li className="list-item">{t("ManagementCompany.3")}</li>
                <li className="list-item">{t("ManagementCompany.4")}</li>
              </ul>
              <ul className="management-company-list">
                <li className="list-item">{t("ManagementCompany.5")}</li>
                <li className="list-item">{t("ManagementCompany.6")}</li>
              </ul>
            </div>
            <span
              href="#"
              className="try-free-button"
              onClick={() => scrollToForm()}
            >
              {t("ManagementCompany.7")}
            </span>
          </div>
        </Col>
        <Col lg={6} xl={6} md={8}>
          <div className="management-company-slider">
            <div
              className="slider-background"
              style={{ backgroundImage: `url(${desktopLogo})` }}
            >
              <div className="slider">
                {
                  renderSlider &&
                  <Slider {...settings}>
                    <div>
                      <img
                        className="d-block w-100 carousel-image"
                        src={deskSlide1()}
                        alt="First slide"
                      />
                    </div>
                    <div>
                      <img
                        className="d-block w-100 carousel-image"
                        src={deskSlide2()}
                        alt="Second slide"
                      />
                    </div>
                    <div>
                      <img
                        className="d-block w-100 carousel-image"
                        src={deskSlide3()}
                        alt="Third slide"
                      />
                    </div>
                  </Slider>
                }

              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row md={12} xs={12}>
        <Col md={12} xs={12}>
          <span
            className="try-free-button-tablet"
            onClick={() => scrollToForm()}
          >
            {t("ManagementCompany.7")}
          </span>
        </Col>
      </Row>
    </>
  );
};

export default ManagementCompany;
