import React from "react";
import mainImage from "../../images/MainImage/main-image.png";
import Image from 'react-bootstrap/Image';
import "./styles.scss";

const MainImage = () => {
  return (
    <Image className="main-image" src={mainImage} alt="Utilitium"/>
  );
};

export default MainImage;
