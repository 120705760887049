import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../components/Common/Layout'
import SEO from '../components/Common/SEO';
import MainImage from '../components/MainImage';
import ComplexSystem from '../components/ComplexSystem';
import MobileBusiness from '../components/MobileBusiness';
import InteractionScheme from '../components/InteractionScheme';
import NewOpportunities from '../components/NewOpportunities';
import ManagementCompany from '../components/ManagementCompany';
import ForResidents from '../components/ForResidents';
import MainSystemFunctional from '../components/MainSystemFunctional';
import ModernTechnologies from '../components/ModernTechnologies';
import AccessApplication from '../components/AccessApplication';
import QuestionsSection from '../components/QuestionsSection';

const IndexPage = () => (
  <Layout>
    <SEO />
    <MainImage />
    <ComplexSystem />
    <MobileBusiness />
    <InteractionScheme />
    <NewOpportunities />
    <ManagementCompany />
    <ForResidents />
    <MainSystemFunctional />
    <ModernTechnologies />
    <AccessApplication />
    <QuestionsSection />
  </Layout>
);

export default IndexPage;
