import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import logo from "../../images/common/Union.svg";
import ArrowTopLeft from "../../images/InteractionScheme/arrow1.svg";
import ArrowTopRight from "../../images/InteractionScheme/arrow2.svg";
import ArrowBottomLeft from "../../images/InteractionScheme/arrow3.svg";
import ArrowBottomRight from "../../images/InteractionScheme/arrow4.svg";
import TabletLeft from "../../images/InteractionScheme/tabletLeft.svg";
import TabletRight from "../../images/InteractionScheme/tabletRight.svg";
import MobileBot from "../../images/InteractionScheme/mobileBot.svg";
import "./styles.scss";

const InteractionScheme = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row lg={12} xl={12}>
        <Col
          lg={12}
          xl={12}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
        >
          <h2 className="interaction-scheme-title">
            {t("InteractionScheme.1")}
          </h2>
        </Col>
      </Row>
      <Row className="logo-wrapper-mobile">
        <Col lg={12} xl={12} xs={12}>
          <div className="interaction-scheme-logo">
            <img className="logo-image" src={logo} alt="Utilitium logo" />
          </div>
        </Col>
      </Row>
      <Row className="interaction-scheme-wrapper" lg={12} xl={12} xs={12}>
        <Col
          lg={6}
          xl={6}
          xs={6}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
          className="interaction-scheme-mobile"
        >
          <div className="interaction-scheme-card">
            <div className="card-title-wrapper">
              <span className="card-title purple">{t("InteractionScheme.2")}</span>
            </div>
            <ul className="card-list purple">
              <li className="card-list-item">{t("InteractionScheme.31")}</li>
              <li className="card-list-item">{t("InteractionScheme.3")}</li>
              <li className="card-list-item">{t("InteractionScheme.4")}</li>
              <li className="card-list-item">{t("InteractionScheme.5")}</li>
            </ul>
            <div className="arrow-wrapper top-left">
              <img src={ArrowTopLeft} alt="Utilitium" />
            </div>
            <div className="arrow-wrapper-tablet left">
              <img src={TabletLeft} alt="Utilitium" />
            </div>
            <div className="arrow-wrapper-mobile bottom">
              <img src={MobileBot} alt="Utilitium" />
            </div>
          </div>
        </Col>
        <Col
          lg={6}
          xl={6}
          xs={6}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          className="interaction-scheme-mobile"
        >
          <div className="interaction-scheme-card">
            <div className="card-title-wrapper">
              <span className="card-title blue">{t("InteractionScheme.6")}</span>
            </div>
            <ul className="card-list blue">
              <li className="card-list-item">{t("InteractionScheme.7")}</li>
              <li className="card-list-item">{t("InteractionScheme.8")}</li>
              <li className="card-list-item">{t("InteractionScheme.9")}</li>
              <li className="card-list-item">{t("InteractionScheme.91")}</li>
            </ul>
            <div className="arrow-wrapper top-right">
              <img src={ArrowTopRight} alt="Utilitium" />
            </div>
            <div className="arrow-wrapper-tablet right">
              <img src={TabletRight} alt="Utilitium" />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="logo-wrapper">
        <Col lg={12} xl={12} xs={12}>
          <div className="interaction-scheme-logo">
            <img className="logo-image" src={logo} alt="Utilitium logo" />
          </div>
        </Col>
      </Row>
      <Row
        className="interaction-scheme-wrapper bottom-section"
        lg={12}
        xl={12}
      >
        <Col
          lg={6}
          xl={6}
          xs={6}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
          className="interaction-scheme-mobile"
        >
          <div className="interaction-scheme-card">
            <div className="card-title-wrapper">
              <span className="card-title orange">{t("InteractionScheme.10")}</span>
            </div>
            <ul className="card-list orange">
              <li className="card-list-item">{t("InteractionScheme.11")}</li>
              <li className="card-list-item">{t("InteractionScheme.12")}</li>
              <li className="card-list-item">{t("InteractionScheme.13")}</li>
            </ul>
            <div className="arrow-wrapper bottom-left">
              <img src={ArrowBottomLeft} alt="Utilitium" />
            </div>
          </div>
        </Col>
        <Col
          lg={6}
          xl={6}
          xs={6}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          className="interaction-scheme-mobile"
        >
          <div className="interaction-scheme-card">
            <div className="card-title-wrapper">
              <span className="card-title green">{t("InteractionScheme.14")}</span>
            </div>
            <ul className="card-list green">
              <li className="card-list-item under">
                {t("InteractionScheme.15")}
              </li>
              <li className="card-list-under">{t("InteractionScheme.16")}</li>
              <li className="card-list-item under">
                {t("InteractionScheme.17")}
              </li>
              <li className="card-list-under">{t("InteractionScheme.18")}</li>
            </ul>
            <div className="arrow-wrapper bottom-right">
              <img src={ArrowBottomRight} alt="Utilitium" />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InteractionScheme;
