import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import mobileLogo from "../../images/ForResidents/mobile.png";
import Slider from "react-slick";
import ENmobSlide1 from "../../images/ForResidents/ENmobSlide1.jpg";
import ENmobSlide2 from "../../images/ForResidents/ENmobSlide2.jpg";
import ENmobSlide3 from "../../images/ForResidents/ENmobSlide3.jpg";
import ENmobSlide4 from "../../images/ForResidents/ENmobSlide4.jpg";
import ENmobSlide5 from "../../images/ForResidents/ENmobSlide5.jpg";
import ENmobSlide6 from "../../images/ForResidents/ENmobSlide6.jpg";
import ENmobSlide7 from "../../images/ForResidents/ENmobSlide7.jpg";
import ENmobSlide8 from "../../images/ForResidents/ENmobSlide8.jpg";
import ENmobSlide9 from "../../images/ForResidents/ENmobSlide9.jpg";
import RUmobSlide1 from "../../images/ForResidents/RUmobSlide1.jpg";
import RUmobSlide2 from "../../images/ForResidents/RUmobSlide2.jpg";
import RUmobSlide3 from "../../images/ForResidents/RUmobSlide3.jpg";
import RUmobSlide4 from "../../images/ForResidents/RUmobSlide4.jpg";
import RUmobSlide5 from "../../images/ForResidents/RUmobSlide5.jpg";
import RUmobSlide6 from "../../images/ForResidents/RUmobSlide6.jpg";
import RUmobSlide7 from "../../images/ForResidents/RUmobSlide7.jpg";
import RUmobSlide8 from "../../images/ForResidents/RUmobSlide8.jpg";
import RUmobSlide9 from "../../images/ForResidents/RUmobSlide9.jpg";
import UAmobSlide1 from "../../images/ForResidents/UAmobSlide1.jpg";
import UAmobSlide2 from "../../images/ForResidents/UAmobSlide2.jpg";
import UAmobSlide3 from "../../images/ForResidents/UAmobSlide3.jpg";
import UAmobSlide4 from "../../images/ForResidents/UAmobSlide4.jpg";
import UAmobSlide5 from "../../images/ForResidents/UAmobSlide5.jpg";
import UAmobSlide6 from "../../images/ForResidents/UAmobSlide6.jpg";
import UAmobSlide7 from "../../images/ForResidents/UAmobSlide7.jpg";
import UAmobSlide8 from "../../images/ForResidents/UAmobSlide8.jpg";
import UAmobSlide9 from "../../images/ForResidents/UAmobSlide9.jpg";
import ThemeContext from "../../contexts/ThemeContext";

import "./styles.scss";

const ForResidents = () => {
  const { t, i18n } = useTranslation();
  const context = useContext(ThemeContext);
  const scrollToForm = context.scrollToForm;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const ENslides = [ENmobSlide1, ENmobSlide2, ENmobSlide3, ENmobSlide4, ENmobSlide5, ENmobSlide6, ENmobSlide7, ENmobSlide8, ENmobSlide9]
  const RUslides = [RUmobSlide1, RUmobSlide2, RUmobSlide3, RUmobSlide4, RUmobSlide5, RUmobSlide6, RUmobSlide7, RUmobSlide8, RUmobSlide9]
  const UAslides = [UAmobSlide1, UAmobSlide2, UAmobSlide3, UAmobSlide4, UAmobSlide5, UAmobSlide6, UAmobSlide7, UAmobSlide8, UAmobSlide9]

  // this function nessesary dinamicly to change the slides depends from the language
  const chooseSlider = () => {
    let actualLeng = i18n.language

    switch (actualLeng) {
      case "en":
        return ENslides;
      case "ru":
        return RUslides;
      case "ua":
        return UAslides;
        default:
          return ENslides;
    }
  }

  return (
    <>
      <Row md={12}>
        <Col md={12}>
          <div className="for-residents-title-tablet">
            <span className="for-residents-subtitle">Utilitium</span>
            <h2 className="for-residents-title">{t("ForResidents.1")}</h2>
          </div>
        </Col>
      </Row>
      <Row className="for-residents-wrapper" lg={12} xl={12} md={12} sm={12} xs={12}>
        <Col className="col-for-slider tablet" lg={6} xl={6} md={6} sm={6} xs={6}>
          <div className="for-residents-slider">
            <div
              className="for-residents-slider-background"
            >
              <img className="slider-background" src={mobileLogo} alt="mobileLogo"/>
              <div className="for-residents-slider-wrapper">
                <div className="residents-slider">
                  <Slider {...settings}>
                  {chooseSlider().map((slide, index) => {
                    return (
                      <div key={index}>
                      <img
                        className="d-block w-100 carousel-image"
                        src={slide}
                        alt="First slide"
                      />
                    </div>
                    )
                  })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <span
            className="try-free-button-mobile"
            onClick={() => scrollToForm(context)}
          >
            {t("ForResidents.6")}
          </span>
        </Col>
        <Col lg={6} xl={6} md={6} sm={6} xs={6}>
          <div className="for-residents-content">
            <div className="for-residents-title-wrapper">
              <span className="for-residents-subtitle">Utilitium</span>
              <h2 className="for-residents-title">{t("ForResidents.1")}</h2>
            </div>
            <div className="residents-lists-wrapper">
              <ul className="for-residents-list left-side">
                <li className="list-item">{t("ForResidents.2")}</li>
                <li className="list-item">{t("ForResidents.3")}</li>
              </ul>
              <ul className="for-residents-list">
                <li className="list-item">{t("ForResidents.4")}</li>
                <li className="list-item">{t("ForResidents.5")}</li>
              </ul>
            </div>
            <span
              className="try-free-button"
              onClick={() => scrollToForm(context)}
            >
              {t("ForResidents.6")}
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ForResidents;
